<template>
  <div class="tab-four">
    <section class="background-wrap">
      <img src="../imgs/leaf-4.png" />
      <img src="../imgs/leaf-5.png" />
    </section>
    <section class="people-info">
      <div class="name">
        <span class="na">{{ photos[activeIndex - 1]["name"] }}</span>
        <span class="wk">{{ photos[activeIndex - 1]["work"] }}</span>
        <img class="line" src="../imgs/line-1.png" alt="" />
        <img class="leader" src="../imgs/leader.png" alt="" />
      </div>
      <div class="desc" v-html="photos[activeIndex - 1]['desc']"></div>
    </section>
    <section class="swiper-wrap">
      <swiper ref="mySwiper" :options="pageOptions">
        <swiper-slide v-for="(item, index) in photos" :key="index">
          <img :src="item.url" alt="" />
        </swiper-slide>
      </swiper>
      <img class="left" src="../imgs/left.png" @click="slidePrev" alt="" />
      <img class="right" src="../imgs/right.png" @click="slideNext" alt="" />
    </section>
  </div>
</template>

<script>
export default {
  data() {
    const _this = this;
    return {
      activeIndex: 1,
      pageOptions: {
        loop: true,
        on: {
          slideChange: function (a) {
            _this.activeIndex = this.realIndex + 1;
          },
        },
      },
      photos: [
        {
          url: require("@/views/pc/wood/imgs/2D组.png"),
          smUrl: require("@/views/pc/wood/imgs/2D头像.png"),
          name: "2D组",
          work: "新木2D组成员",
          desc: `
          <p>负责任人： 豹豹，做一个有爱、有战斗力的团队</p>
          <p>工作涵盖： 图标、原画、美宣</p>
          <p>参与项目：《原神》《大富翁》《QQ飞车》《魂斗罗》《一拳超人》《最强蜗牛》《一梦江湖》《斗罗大陆》等</p>
          `,
        },
        {
          url: require("@/views/pc/wood/imgs/3D组.png"),
          smUrl: require("@/views/pc/wood/imgs/3D头像.png"),
          name: "3D组",
          work: "新木3D组成员",
          desc: `
          <p>工作涵盖： 场景次世代、动作、3渲2、特效、买量视频</p>
          <p>参与项目：《王牌竞速》《QQ飞车》《拳皇》《铁甲雄兵》《神舞幻想2》等</p>
          `,
        },
        {
          url: require("@/views/pc/wood/imgs/UI组.png"),
          smUrl: require("@/views/pc/wood/imgs/UI头像.png"),
          name: "UI组",
          work: "新木UI组成员",
          desc: `
          <p>工作涵盖： UI风格设定、Banner设计、UI动效</p>
          <p>参与项目：《英雄联盟》《花亦山心之月》《梦幻西游》《武林闲侠》《荒野行动》《蛋仔派对》《非人学园》《黑潮之上》《幻书启世录》等</p>
          `,
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-four {
  height: 100%;
  overflow: hidden;
  position: relative;
  .people-info {
    width: rem(320);
    height: rem(600);
    position: absolute;
    left: rem(205);
    top: rem(200);
    z-index: 100;
    .name {
      height: rem(220);
      position: relative;
      transform: scale(1.5);
      transition: transform 0.3s, opacity 0.3s;
      opacity: 0;
      .na {
        font-size: 28px;
        position: absolute;
        font-weight: bold;
        left: rem(0);
        letter-spacing: 2px;
        top: rem(50);
        transform: rotate(-8deg);
        z-index: 100;
      }
      .wk {
        font-size: 13px;
        position: absolute;
        // font-weight: bold;
        color: #8954ec;
        font-weight: 600;
        left: rem(110);
        top: rem(120);
        width: 100%;
        z-index: 2;
        transform: rotate(-8deg);
      }
      .line {
        width: rem(224);
        position: absolute;
        right: rem(50);
        z-index: 10;
      }
      .leader {
        width: rem(158);
        position: absolute;
        right: rem(20);

        top: rem(65);
      }
    }
    .desc {
      font-size: 12px;
      padding: rem(50) 0 0 0;
      line-height: rem(70);
      color: #999;
      font-weight: 500;
      transform: translateY(20px);
      transition: transform 0.5s, opacity 0.6s;
      opacity: 0;
      width: rem(800);
    }
  }
  .swiper-wrap {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 100%;
    height: rem(1332 * 0.8);
    transition: opacity 0.6s;
    opacity: 0;
    .swiper-slide {
      width: 100%;
      height: rem(1332 * 0.8);
      overflow: hidden;
      text-align: center;
      img {
        height: 100%;
      }
    }
    .left {
      width: rem(54);
      position: absolute;
      left: rem(70);
      top: rem(280);
      z-index: 2;
    }
    .right {
      width: rem(54);
      position: absolute;
      top: rem(258);
      right: rem(100);
      z-index: 2;
    }
  }
  .background-wrap {
    img {
      &:nth-of-type(1) {
        width: rem(1680);
        position: absolute;
        top: rem(278);
        left: rem(264);
        transform: translateX(100%);
        transition: transform 0.3s;
      }
      &:nth-of-type(2) {
        width: rem(1041);
        position: absolute;
        left: rem(-80);
        bottom: rem(-40);
        transform-origin: -20% 80%;
        transform: rotate(90deg);
        transition: transform 0.3s;
      }
    }
  }
}
.swiper-slide-active {
  .people-info {
    .name {
      transform: scale(1);
      opacity: 1;
      transition-delay: 0.6s;
    }
    .desc {
      transform: translateY(0px);
      opacity: 1;
      transition-delay: 0.3s;
      color: rgba(22, 22, 22, 0.8);
    }
  }
  .swiper-wrap {
    opacity: 1;
    transition-delay: 0.6s;
  }
  .background-wrap {
    img {
      &:nth-of-type(1) {
        transform: translateX(0%);
        transition-delay: 0.3s;
      }
      &:nth-of-type(2) {
        transform: rotate(0deg);
        transition-delay: 0.3s;
      }
    }
  }
}
</style>
