<template>
  <div class="tab-three">
    <section class="background-wrap">
      <img src="../imgs/leaf-2.png" />
      <img src="../imgs/leaf-3.png" />
      <img src="../imgs/xinm-1.png" />
    </section>
    <div class="intro-info">
      <img :src="axisInfos[active].url" alt="" />
      <p>{{ axisInfos[active]["desc"] }}</p>
    </div>
    <div class="time-aixs">
      <div class="line">
        <div class="circular">
          <div class="time">
            <ul class="year" :class="{ ['year-' + active]: true }">
              <li :class="[active == index && 'active']" v-for="(item, index) in axisInfos" :key="index" @click="change(index)">
                {{ item.year }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventUtil } from "@/utils";
export default {
  data() {
    return {
      active: 1,
      axisInfos: [
        {
          year: "2021年6月",
          desc: "新木创意团队成员超100人。囊括了原画、设定、动画、界面、动特效、宣传美术等2D美术全品类。还顺利成为腾讯、西山居、字节跳动供应商。",
          url: require("@/views/pc/wood/imgs/ev-5.png"),
        },
        {
          year: "2020年6月",
          desc: "团队扩大至60人+，成为网易核心供应商。参与了《一梦江湖》、《黑潮之上》、《幻书启世录》、《第五人格》、《王牌竞速》、《率土之滨》、《天下》、《梦幻西游》等项目。",
          url: require("@/views/pc/wood/imgs/ev-4.png"),
        },
        {
          year: "2019年8月",
          desc: "新木创意团队有幸参与了《三国志幻想大陆》、《三国志战略版》等项目的2D视觉设计部分。",
          url: require("@/views/pc/wood/imgs/ev-3.png"),
        },
        {
          year: "2019年5月",
          desc: "正式成为bilibili、阿里游戏、37互娱、波克城市、心动网络、完美世界、巨人等大厂的美术品类供应商。",
          url: require("@/views/pc/wood/imgs/ev-2.png"),
        },
        {
          year: "2018年4月",
          desc: "在集合了一批优质成员后，上海新木创意团队终于在2018年4月顺利诞生。",
          url: require("@/views/pc/wood/imgs/ev-1.png"),
        },
      ],
    };
  },
  methods: {
    change(index) {
      this.active = index;
    },
    handleTouchYear() {
      const el = document.querySelector(".tab-three");
      EventUtil.listenTouchDirection(
        el,
        true,
        null,
        () => {
          if (this.active == 0) return;
          this.active--;
        },
        null,
        () => {
          if (this.active == this.axisInfos.length - 1) return;
          this.active++;
        }
      );
    },
  },
  mounted() {
    this.handleTouchYear();
  },
};
</script>

<style lang="scss" scoped>
.tab-three {
  height: 100%;
  position: relative;
  overflow: hidden;
  .intro-info {
    height: rem(800);
    transform: rotate(-4deg);
    position: absolute;
    top: rem(340);
    right: rem(-20);
    img {
      width: rem(1000);
      transform: translateX(100%);
      transition: transform 0.3s;
    }
    p {
      font-size: rem(38);
      font-weight: 600;
      color: #292929;
      transform: rotate(-2deg);
      padding: rem(20) rem(0) 0 rem(30);
      box-sizing: border-box;
      width: rem(800);
      transform: translateX(-200%);
      transition: transform 0.3s;
    }
  }
  .time-aixs {
    .line {
      width: 120vw;
      height: 2px;
      // background: #8954ec;
      position: absolute;
      top: rem(1200);
      transform: rotate(-5deg) translateX(rem(-30));
      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 0;
        background: #8954ec;
        z-index: -1;
        transition: width 0.6s;
      }
      .circular {
        width: rem(50);
        height: rem(50);
        background: #facb2b;
        border-radius: 50%;
        position: absolute;
        left: rem(370);
        top: 50%;
        transform: translateY(-50%);
        // border: 1px solid #f2f3f5;
        .time {
          width: 100vw;
          height: rem(80);
          // padding: rem(100) 0;
          left: rem(-370);
          position: absolute;
          bottom: rem(-90);
          mask: linear-gradient(-90deg, transparent, #fff 50%);
          .year {
            width: 150vw;
            position: absolute;
            transition: transform 1s;
            transform: rotate(10deg);
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.year-0 {
              transform: translateX(25vw);
            }
            &.year-1 {
              transform: translateX(-5vw);
            }
            &.year-2 {
              transform: translateX(-38vw);
            }
            &.year-3 {
              transform: translateX(-70vw);
            }
            &.year-4 {
              transform: translateX(-100vw);
            }
            &.year-5 {
              transform: translateX(-99vw);
            }
            li {
              font-size: rem(42);
              font-weight: bold;
              width: 25vw;
              color: #555;
              text-align: center;
              transform: rotate(-4deg) translateY(20px);
              opacity: 0;
              transition: transform 0.5s, opacity 0.3s;
              &.active {
                font-size: rem(47);
                color: #00d6e4;
              }
            }
          }
        }
      }
    }
  }
  .background-wrap {
    img {
      position: absolute;
      &:nth-of-type(1) {
        width: rem(772);
        bottom: rem(-20);
        left: rem(80);
        z-index: 2;
        transform-origin: -25% 70%;
        transform: rotate(90deg);
        transition: transform 0.3s 0.3s;
      }
      &:nth-of-type(2) {
        width: rem(874);
        bottom: 0;
        left: rem(80);
        z-index: 1;
        transform: translateY(120%);
        transition: transform 0.3s;
      }
      &:nth-of-type(3) {
        width: rem(283);
        bottom: rem(265);
        left: rem(552);
        z-index: 3;
        transform: scale(1.5);
        opacity: 0;
        transition: transform 0.3s, opacity 0.3s;
      }
    }
  }
}
.swiper-slide-active {
  .tab-three {
    .intro-info {
      img {
        transform: translateX(0);
        transition-delay: 0.3s;
      }
      p {
        transform: translateX(0);
        transition-delay: 0.3s;
      }
    }
    .time-aixs {
      .line {
        &::after {
          transition-delay: 0.2s;
          width: 100%;
        }
        .circular {
          .year {
            li {
              transform: rotate(-4deg) translateY(0px);
              opacity: 1;
              &:nth-of-type(1) {
                transition-delay: 0.2s;
              }
              &:nth-of-type(2) {
                transition-delay: 0.3s;
              }
              &:nth-of-type(3) {
                transition-delay: 0.4s;
              }
              &:nth-of-type(4) {
                transition-delay: 0.5s;
              }
              &:nth-of-type(5) {
                transition-delay: 0.6s;
              }
              &:nth-of-type(6) {
                transition-delay: 0.7s;
              }
            }
          }
        }
      }
    }
    .background-wrap {
      img {
        &:nth-of-type(1) {
          transform: rotate(0deg);
          transition-delay: 0.4s;
        }
        &:nth-of-type(2) {
          transform: translateY(0);
          transition-delay: 0.4s;
        }
        &:nth-of-type(3) {
          transform: scale(1);
          opacity: 1;
          transition-delay: 0.5s;
        }
      }
    }
  }
}
</style>
