<template>
  <div class="tab-five">
    <img src="../imgs/leaf-6.png" />
    <img src="../imgs/leaf-7.png" />
    <img src="../imgs/xinm-2.png" />
    <img @click="openPopup" src="../imgs/room.png" />
    <img src="../imgs/joinus.png" />
    <div class="job-wrap">
      <h3>招聘职位</h3>
      <ul class="job-list">
        <li v-for="(item, index) in list" :key="index">
          <p>
            <span>{{ item.name }}</span>
            <span>共{{ item.num }}个职位</span>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      popupInfo: {
        files: [
          { url: require("@/views/pc/wood/imgs/fl.png") },
          { url: require("@/views/pc/wood/imgs/fl-2.png") },
          { url: require("@/views/pc/wood/imgs/fl-3.png") },
          { url: require("@/views/pc/wood/imgs/fl-4.png") },
          { url: require("@/views/pc/wood/imgs/fl-5.png") },
        ],
        title: "年会照片",
        desc: "欢乐的时光总是值得留恋，新木周年庆进行中</br>每年新木都会进行一次全体成员参与的年会互动，游戏美食、惊喜抽奖应有尽有。",
      },
    };
  },
  methods: {
    openPopup() {
      this.$emit("open-popup", this.popupInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-five {
  height: 100%;
  overflow: hidden;
  position: relative;
  img {
    &:nth-of-type(1) {
      width: rem(924);
      position: absolute;
      top: rem(254);
      left: rem(-205);
      z-index: 1;
      transform-origin: -10% 90%;
      transform: rotate(-90deg);
      transition: transform 0.3s;
    }
    &:nth-of-type(2) {
      width: rem(776);
      position: absolute;
      top: rem(307);
      left: rem(-125);
      z-index: 11;
      transform-origin: -25% 60%;
      transform: rotate(-90deg);
      transition: transform 0.3s;
    }
    &:nth-of-type(3) {
      width: rem(367);
      position: absolute;
      top: rem(454);
      left: rem(51);
      z-index: 12;
      opacity: 0;
      transition: opacity 1s;
    }
    &:nth-of-type(4) {
      width: rem(1169);
      position: absolute;
      top: rem(461);
      left: rem(-152);
      z-index: 13;
      transform: translateX(-100%);
      transition: transform 0.3s;
    }
    &:nth-of-type(5) {
      width: rem(454);
      position: absolute;
      top: rem(1284);
      right: rem(18);
      transform: scale(1.5);
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s;
    }
  }
  .job-wrap {
    position: absolute;
    top: rem(1430);
    transform: rotate(-12deg);
    h3 {
      color: #8a53ec;
      font-size: 13px;
      padding: 0 0 rem(40) rem(50);
      opacity: 0;
      transition: opacity 0.3s;
    }
    .job-list {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100vw;
      padding: 0 rem(40);
      box-sizing: border-box;
      height: rem(160);
      padding-top: rem(30);
      // border-top: 2px solid #fac82b;
      transform: translateX(-100%);
      transition: transform 0.3s;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 0;
        background: #fac82b;
        transition: width 1s;
        top: 0;
        left: 0;
      }

      li {
        height: 100%;
        p {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: column;
          transform: rotate(4deg);
          span {
            font-size: 14px;
            &:nth-of-type(2) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.swiper-slide-active {
  .tab-five {
    img {
      &:nth-of-type(1) {
        transition-delay: 0.3s;
        transform: rotate(0deg);
      }
      &:nth-of-type(2) {
        transition-delay: 0.4s;
        transform: rotate(0deg);
      }
      &:nth-of-type(3) {
        opacity: 1;
        transition-delay: 0.4s;
      }
      &:nth-of-type(4) {
        transition-delay: 0.5s;
        transform: translateX(0);
      }
      &:nth-of-type(5) {
        transition-delay: 0.7s;
        transform: scale(1);
        opacity: 1;
      }
    }
    .job-wrap {
      h3 {
        transition-delay: 0.3s;
        opacity: 1;
      }
      .job-list {
        transition-delay: 0.4s;
        transform: translateX(rem(-20));
        &::after {
          transition-delay: 0.2s;
          width: 100%;
        }
      }
    }
  }
}
</style>
