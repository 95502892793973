<template>
  <div class="tab-two">
    <section class="background-wrap">
      <img src="../imgs/leaf-10.png" />
      <img src="../imgs/leaf-11.png" />
    </section>
    <section class="swiper-wrap">
      <swiper ref="pageSwiper" :options="pageOptions">
        <swiper-slide v-for="(item, index) in lists" :key="index">
          <span class="index">{{ index | indexF }}</span>
          <img class="pic" :src="item.files[0].url" alt="" />
          <p class="title">
            <span class="l"></span>
            <span class="t">{{ item.title }}</span>
            <span class="r"></span>
          </p>
        </swiper-slide>
      </swiper>
    </section>
    <div class="prev" @click="slidePrev" alt="" />
    <div class="next" @click="slideNext" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    lists: {
      type: Array,
      default: [],
    },
  },
  data() {
    const _this = this;
    return {
      activeIndex: 1,
      pageOptions: {
        on: {
          slideChange: function (a) {
            _this.activeIndex = this.activeIndex + 1;
          },
          click: (event) => {
            let src = event.target.src;
            _this.lists.map((item) => {
              if (src.indexOf(item.files[0].url) != -1) {
                _this.$emit("open-popup", { ...item });
              }
            });
          },
        },
        initialSlide: 4,
        loopAdditionalSlides: 3,
        loop: true,
      },
    };
  },

  computed: {
    pageSwiper() {
      return this.$refs.pageSwiper.$swiper;
    },
    prev() {
      return this.lists[this.activeIndex - 2] || this.lists[this.lists.length - 1] || null;
    },
    next() {
      return this.lists[this.activeIndex] || this.lists[0] || null;
    },
  },
  filters: {
    indexF(value) {
      const temp = value + 1;
      return temp >= 10 ? temp : "0" + temp;
    },
  },
  methods: {
    slidePrev(type) {
      if (this.activeIndex == 1) {
        this["pageSwiper"].slideTo(this.lists.length - 1);
      } else {
        this["pageSwiper"].slidePrev();
      }
    },
    slideNext(type) {
      if (this.activeIndex == this.lists.length) {
        this["pageSwiper"].slideTo(0);
      } else {
        this["pageSwiper"].slideNext();
      }
    },
    openPopup(item) {
      // this.$emit("open-popup", { ...item });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.tab-two {
  height: 100%;
  overflow: hidden;
  position: relative;
  .background-wrap {
    img {
      position: absolute;
      &:nth-of-type(1) {
        width: rem(804);
        bottom: rem(-190);
        right: rem(-269);
        z-index: 2;
        transform-origin: -25% 70%;
        transform: rotate(90deg);
        transition: transform 0.3s 0.3s;
      }
      &:nth-of-type(2) {
        width: rem(1015);
        bottom: rem(-312);
        right: rem(-455);
        z-index: 1;
        transform: translateY(120%);
        transition: transform 0.3s;
      }
    }
  }
  .swiper-wrap {
    height: 100%;
    padding-top: rem(450);
    box-sizing: border-box;
    position: relative;
    opacity: 0;
    transform: scale(1.5);
    transition: transform 0.3s, opacity 0.3s;
    .swiper-slide {
      position: relative;
      width: 80% !important;
      margin: 0 10%;
      &-prev {
        right: -32%;
        .pic {
          width: 60% !important;
          margin-top: rem(450) !important;
        }
        .title {
          display: none !important;
        }
      }
      &-next {
        left: -32%;
        .pic {
          width: 60% !important;
          margin-top: rem(400) !important;
        }
        .title {
          display: none !important;
        }
      }
      .index {
        color: #fac82b;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 70px;
        font-weight: bold;
        z-index: -1;
      }
      .pic {
        // width: rem(833);
        width: 100%;
        display: block;
        margin: rem(70) auto;
        transition: all 0.5s;
      }
      .title {
        position: absolute;
        bottom: rem(90);
        width: 100%;
        text-align: center;
        font-size: 12px;
        background: url("../imgs/bg-1.png") no-repeat;
        background-size: rem(356) auto;
        height: rem(80);
        background-position: center bottom;
        display: flex;
        align-items: center;
        justify-content: center;
        .l {
          width: rem(150);
          height: 2px;
          background: #baa3e6;
          display: inline-block;
        }
        .t {
          margin: 0 8px;
          font-weight: bold;
          font-size: 13px;
        }
        .r {
          width: rem(150);
          height: 2px;
          display: inline-block;
          background: #baa3e6;
        }
      }
    }
  }
  .prev {
    height: rem(426);
    width: rem(400);
    position: absolute;
    left: rem(-340);
    top: rem(930);
    z-index: 10;
    opacity: 0;
  }
  .next {
    width: rem(400);

    height: rem(426);
    right: rem(-340);
    position: absolute;
    top: rem(930);
    z-index: 10;
    opacity: 0;
  }
}
.swiper-slide-active {
  .tab-two {
    .swiper-wrap {
      opacity: 1;
      transform: scale(1);
      transition-delay: 0.3s;
    }
  }
  .background-wrap {
    img {
      &:nth-of-type(1) {
        transform: rotate(0deg);
        transition-delay: 0.4s;
      }
      &:nth-of-type(2) {
        transform: translateY(0);
        transition-delay: 0.4s;
      }
    }
  }
}
</style>
