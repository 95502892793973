<template>
  <div class="tab-one">
    <section class="background-wrap">
      <img src="../imgs/leaf-1.png" alt="" />
    </section>
    <section class="images-wrap">
      <div class="img-item" v-for="(item, index) in lists" :key="index" @click="openPopup(item)">
        <img :src="item.smUrl" alt="" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lists: [
        {
          smUrl: require("../../../pc/wood/imgs/2@2x-2.png"),
          files: [
            { url: require("@/views/pc/wood/imgs/card2-1.png") },
            { url: require("@/views/pc/wood/imgs/card2-2.png") },
            { url: require("@/views/pc/wood/imgs/card2-3.png") },
            { url: require("@/views/pc/wood/imgs/card2-4.png") },
            { url: require("@/views/pc/wood/imgs/card2-5.png") },
          ],
          title: "研发美术资源",
          desc: "在深度参与了大量优质项目后，我们已经具备了完整的研发美术资源产出能力。同时，在大厂各个垂直细分的需求之外，我们还成功承接了中小型研发公司的整包需求。",
        },
        {
          smUrl: require("../../../pc/wood/imgs/4@2x-4.png"),
          files: [{ url: require("@/views/pc/wood/imgs/card4-1.png") }],
          title: "企业人才输送",
          desc: "我们具备专业化的人才教育体系，还与很多游戏行业一线公司建立了人才输送渠道。 每年我们都有大量优秀学员被大厂录用。同时我们也有完备的人才管理模式，能提 供健全的人才成长计划。",
        },
        {
          smUrl: require("../../../pc/wood/imgs/1@2x-1.png"),
          files: [
            { url: require("@/views/pc/wood/imgs/card1-1.png") },
            { url: require("@/views/pc/wood/imgs/card1-2.png") },
            { url: require("@/views/pc/wood/imgs/card1-3.png") },
            { url: require("@/views/pc/wood/imgs/card1-4.png") },
            { url: require("@/views/pc/wood/imgs/card1-5.png") },
          ],
          title: "概念视觉设计",
          desc: "游戏项目前期的视觉设定非常重要，能够流畅高效的进行风格探索是每个项目组的刚需。我们有非常专业的、多样化的美术专家，能满足目前市面上绝大部分的视觉风格设计需求。",
        },
        {
          smUrl: require("../../../pc/wood/imgs/3@2x-3.png"),
          files: [
            { url: require("@/views/pc/wood/imgs/card3-1.png") },
            { url: require("@/views/pc/wood/imgs/card3-2.png") },
            { url: require("@/views/pc/wood/imgs/card3-3.png") },
            { url: require("@/views/pc/wood/imgs/card3-4.png") },
            { url: require("@/views/pc/wood/imgs/card3-5.png") },
          ],
          title: "运营美术资源",
          desc: "经过多年积累，我们对于运营美术的需求理解和制作已经非常专业。在运营中最常见的banner、美宣KV、动特效视频、项目周边视觉设计等方面我们都有充足的涉猎。",
        },
      ],
    };
  },
  methods: {
    openPopup(item, index) {
      this.$emit("open-popup", { ...item });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-one {
  height: 100%;
  padding: rem(144);
  background: url("../imgs/t1-bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  .background-wrap {
    img {
      width: rem(1051);
      position: absolute;
      bottom: rem(-350);
      right: rem(-520);
      transform: rotate(0deg);
      transform-origin: right top;
      transform: rotate(-90deg);
    }
  }

  .images-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .img-item {
      transition: transform 0.5s;
      &:nth-of-type(1) {
        transform: translateY(rem(-1000));
      }
      &:nth-of-type(2) {
        margin-top: rem(100);
        transform: translateY(rem(-1000));
      }
      &:nth-of-type(3) {
        margin-top: rem(4);
        transform: translateY(rem(1500));
      }
      &:nth-of-type(4) {
        margin-top: rem(110);
        transform: translateY(rem(1500));
      }
      img {
        width: rem(350);
      }
    }
  }
}
.swiper-slide-active {
  .tab-one {
    .background-wrap {
      img {
        transform: rotate(0deg);
        transition: transform 0.5s 0.5s;
      }
    }
  }
  .images-wrap {
    .img-item {
      &:nth-of-type(1) {
        transform: translateY(0);
        transition-delay: 0.2s;
      }
      &:nth-of-type(2) {
        transform: translateY(0);
        transition-delay: 0.3s;
      }
      &:nth-of-type(3) {
        transform: translateY(0);
        transition-delay: 0.4s;
      }
      &:nth-of-type(4) {
        transform: translateY(0);
        transition-delay: 0.5s;
      }
    }
  }
}
</style>
